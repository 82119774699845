import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  private functionCallSource = new BehaviorSubject<boolean>(false);
  // private editCommunityModalSource = new BehaviorSubject<boolean>(false);
  functionCalled$ = this.functionCallSource.asObservable();
  // editCommunityModalSourceCalled$ = this.editCommunityModalSource.asObservable();

  constructor() { }

  callFunction() {
    this.functionCallSource.next(true);
  }
  
  // skills
  private GetSkillsSource = new BehaviorSubject<boolean>(false);
  
  GetSkillsSourceCalled$ = this.GetSkillsSource.asObservable();
  callGetSkills() {
    this.GetSkillsSource.next(true);
  }
  

  // community
  private editCommunityModalSource = new BehaviorSubject<{ isOpen: boolean, data?: any, text?: any }>({ isOpen: false });

  editCommunityModalSourceCalled$ = this.editCommunityModalSource.asObservable();
  // Function to open the modal with optional data
  editCommunityModalOpen(data?: any,text?:any) {
    this.editCommunityModalSource.next({ isOpen: true, data,text });
  }
  private closeCommunityModalSource = new BehaviorSubject<{ isOpen: boolean}>({ isOpen: false });

  closeCommunityModalSourceCalled$ = this.closeCommunityModalSource.asObservable();
  // Function to close the modal
  closeCommunityModal() {
    this.closeCommunityModalSource.next({ isOpen: false });
  }

  // redeem detail
  private openRedeemDetail = new BehaviorSubject<{ isOpen: boolean, data?: any }>({ isOpen: false });

  openRedeemDetailSourceCalled$ = this.openRedeemDetail.asObservable();

  openRedeemDetailModalOpen(data?: any) {
    this.openRedeemDetail.next({ isOpen: true, data });
  }
  // redeem confirmation detail
  private openRedeemConfirmation = new BehaviorSubject<{ isOpen: boolean, data?: any }>({ isOpen: false });

  openRedeemConfirmationSourceCalled$ = this.openRedeemConfirmation.asObservable();

  openRedeemConfirmationModalOpen(data?: any) {
    this.openRedeemConfirmation.next({ isOpen: true, data });
  }
  private closeRedeemConfirmation = new BehaviorSubject<{ isOpen: boolean}>({ isOpen: false });

  closeRedeemConfirmationSourceCalled$ = this.closeRedeemConfirmation.asObservable();
  closeRedeemConfirmationModal() {
    this.closeRedeemConfirmation.next({ isOpen: false });
  }

  // change border
  private openChangeBorder = new BehaviorSubject<{ isOpen: boolean, data?: any }>({ isOpen: false });

  openChangeBorderSourceCalled$ = this.openChangeBorder.asObservable();

  openChangeBorderModalOpen(data?: any) {
    this.openChangeBorder.next({ isOpen: true, data });
  }
  closeChangeBorderModal(data?: any) {
    this.openChangeBorder.next({ isOpen: false, data });
  }


  // language change

  private currentLanguage = new BehaviorSubject<string>('English');  // Default language
  language$ = this.currentLanguage.asObservable();  // Observable for components to subscribe to

  changeLanguage(language: string) {
    this.currentLanguage.next(language);
  }
}
