import { Component } from '@angular/core';

@Component({
  selector: 'app-change-border',
  templateUrl: './change-border.component.html',
  styleUrls: ['./change-border.component.css']
})
export class ChangeBorderComponent {

}
