import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from '../services/auth-service.service'; // Adjust the path as necessary

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthServiceService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isUserOnline = this.authService.isUserOnline();
    if (!isUserOnline) {
      this.authService.setRedirectUrl(state.url);
      sessionStorage.setItem('redirectUrl',state.url) ;

      this.router.navigate(['/']); // Redirect to login if not online
    }
    return isUserOnline;
  }
}