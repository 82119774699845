<!-- Change border modal -->
<div id="Border-Modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-4xl max-h-full">
          <!-- Modal content -->
          <div class="addGamesModal relative bgDarkGray rounded-lg shadow dark:bg-gray-700 flex-grow overflow-auto">
              <div class="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                <div class="flex">
                  <!-- <p [class.hidden]="selectedCount > 0"  data-modal-target="edit-gamesModal" data-modal-toggle="edit-gamesModal" class="cursor-pointer pink font-medium text-xs self-center ml-2">EDIT LIST</p> -->
                  
                  <h3  class="cursor-pointer text-xl liMainText text-white dark:text-white">
                      CHANGE PROFILE BORDER (8/30)
                  </h3>
                  </div>
                  <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="changeBorder-Modal">
                      <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                      </svg>
                      <span class="sr-only">Close modal</span>
                  </button>
              </div>
              
              <!-- Modal body -->
          <div class="contentMain p-4 md:p-5 space-y-4">
            <div class="flex ">
              <div class="profileImageMain mr-8 relative">
                  <img class="profileImage" src="../../../../../assets/images/profilePerson.jpg" alt="Uploaded Image">
                  <img class="profileBorder" src="../../../../../assets/images/borderProfile.png" alt="">
                </div>
                <div >
                
                  <h3 class="text-white font-medium mb-2">Border name holder</h3>
                  <p class="text-gray-400 text-xs font-light">Description holder</p>
                 
                </div>
            </div> 
            <hr class="bg-gray-400">
            <div class="mb-2">
              <div class="grid grid-cols-2 gap-2 md:grid-cols-5 md:gap-3">
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
               <div>
                <img  src="../../../../../assets/images/borderProfile.png" alt="">
               </div>
              </div>
            </div>
          </div>
          <!-- footer -->
          <div class="bgDarkGray items-center w-full sticky bottom-0 p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                   type="button"
                   id=""
                   class="bgpink cutBtn w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                 >
                   CONFIRM
                 </button>
          </div>
      </div>
      </div>
  </div>