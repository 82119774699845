import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthServiceService } from '../auth-service.service';
import { Router } from '@angular/router';
// import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class InterceptorTokenService  implements HttpInterceptor {

  constructor(
    private toastr: ToastrService,
    private authService: AuthServiceService,
    private router: Router,
    private translate: TranslateService
    // private route: ActivatedRouteSnapshot,
    // private state: RouterStateSnapshot
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const excludedUrls = [
      '/register',
      '/login',
      '/verify-otp',
      '/otpVerification',
      '/forgotPassword',
      '/resetPassword',
    ];
    const isExcluded = excludedUrls.some(url => req.url.includes(url));

    let token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!isExcluded && token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!navigator.onLine) {
          this.translate.get(['error.You_have_lost_internet_connection', 'error.Connection_Error']).subscribe(translations => {
            this.toastr.error(translations['error.You_have_lost_internet_connection'], translations['error.Connection_Error']);
          });
        } else if (error.status === 0) {
          this.translate.get(['error.No_response_from_the_server_Please_try_again_later', 'error.Server_Error']).subscribe(translations => {
            this.toastr.error(translations['error.No_response_from_the_server_Please_try_again_later'], translations['error.Server_Error']);
          });
        }  else if (error.status === 401) {
          // Handle 401 Unauthorized error here
          const currentUrl = this.router.url; // Get the current browser URL
        if(currentUrl == '/'){

        }else{

          this.authService.setRedirectUrl(currentUrl);
        }
          this.authService.logout();

        } 
        else {
        }
        return throwError(error);
      })
    );
  }
}