export const environment = {
    apiUrl: 'https://backend.unipin.quytech.co/v1',
    firebase : {
        apiKey: "AIzaSyAhXkPrS7R1EMpAflukoPZSyJxPXjlis2A",
        authDomain: "seaca-dev.firebaseapp.com",
        projectId: "seaca-dev",
        storageBucket: "seaca-dev.appspot.com",
        messagingSenderId: "222073220475",
        appId: "1:222073220475:web:71ddf3b2b8ce7ec5d621de",
        measurementId: "G-QQD4VF5YGX",
        vapidKey: "BGzzzmGiuWS5UFsWvKhNvQYjZZWxhOoCmeywOG-0fLImHJ7-NIa4LO1ddbJhzqVDICx6LQ0fjtfvImsYeYFW3fE"

      }
   
};