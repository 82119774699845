import {  AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { initFlowbite, initModals } from 'flowbite';
import { initDrawers } from 'flowbite';
import 'flowbite';
import { Flowbite } from './flowbite.decorator';
import { HttpClient } from '@angular/common/http';
import { SharedServiceService } from './core/services/shared-service.service';
import { CountryServiceService } from './core/services/country/country-service.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ProfileServiceService } from './core/services/profile-service.service';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from './core/services/auth-service.service';
import { Subscription } from 'rxjs';
@Flowbite()

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
// @Flowbite();
export class AppComponent implements OnInit, AfterViewInit,OnDestroy {
  constructor(
    private http: HttpClient,
    private countryServiceService: CountryServiceService,
    private translate: TranslateService,
    private sharedService: SharedServiceService,
    private spinner: NgxSpinnerService,
    private profileService: ProfileServiceService,
    private toastr: ToastrService,
    private authService: AuthServiceService,
  ) {

    this.authService.isUserOnline$.subscribe(isOnline => {
      if(isOnline){
        const defaultLang =  'English';
      this.translate.setDefaultLang(defaultLang);
      this.getuserProfile()
    }else{
      const defaultLang =  'English';
      this.translate.setDefaultLang(defaultLang);

    }
    });
  }

  title = 'seaca';
  location:any = {};
  private apiUrl = 'https://nominatim.openstreetmap.org/reverse';
  private languageSubscription!: Subscription;
  userProfileFetched: boolean = false;

  ngOnInit() {
    initFlowbite();
    initModals();
    // navigator.geolocation.getCurrentPosition((position)=>{
    //   this.location = position.coords
    //   this.reverseGeocode(position.coords.latitude,position.coords.longitude)
    // })
    this.languageSubscription = this.sharedService.language$.subscribe((language: any) => {
      this.translate.use(language);

      
    });
  }
  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }
  getuserProfile(){
    // this.spinner.show();
    this.profileService.getProfile().subscribe(
       (response:any) => {
        // this.spinner.hide();
        if(response.code == 200){
        if(localStorage.getItem('token')){
          localStorage.setItem('user', JSON.stringify(response?.data));
        }else{
          
            sessionStorage.setItem('user', JSON.stringify(response?.data));
          }
         
      
      }
      (error:any) => {
        // this.spinner.hide();
        this.toastr.error(error?.error?.errorMessage, '');

      }
      }
    );
  }
  switchLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);  // Store the selected language for future sessions
  }
  // reverseGeocode(lat: number, lon: number): void {
  //   const url = `${this.apiUrl}?lat=${lat}&lon=${lon}&format=json`;

  //   this.http.get<any>(url).subscribe(response => {
  //     if (response && response.address) {
  //       // this.location = response;
  //       this.location = response.address.country;
  //       this.updateData()
  //     } else {
  //       this.location = 'Location not found';
  //     }
  //   }, error => {
  //     this.location = 'Error fetching location';
  //   });
  // }
  updateData() {
    this.countryServiceService.setData(this.location);
  }
  ngAfterViewInit() {
    initFlowbite();
  }
 
}
